import React, { useEffect, useState } from "react";
import "./qualification.css";

let CURRENT_GPA = 3.91;

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    // const [currentGPA, setCurrentGPA] = useState(null);
    // const [loading, setLoading] = useState(true);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    /* ========== GPA Calculator ========== */
    // useEffect(() => {
    //     fetch("https://res-proxy-server.vercel.app/api/result/fetch-gpa/12394")
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Network response was not ok");
    //             }
    //             return response.json();
    //         })
    //         .then((data) => {
    //             setCurrentGPA(data.gpa);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //             setLoading(false);
    //         });
    // }, []);

    return (
        <section className="qualification section" id="portfolio">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">My Personal Journey</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__button qualification__active button--flex"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(1)}
                    >
                        <i className="uil uil-graduation-cap qualification__icon"></i>
                        Education
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? "qualification__button qualification__active button--flex"
                                : "qualification__button button--flex"
                        }
                        onClick={() => toggleTab(2)}
                    >
                        <i className="uil uil-briefcase-alt qualification__icon"></i>
                        Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    <div
                        className={
                            toggleState === 1
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Bachelor's Degree
                                </h3>
                                <span className="qualification__subtitle">
                                    University of Ruhuna
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2022-present
                                </div>
                                <span className="qualification__subsubtitle">
                                    {/* Current GPA - {loading ? "Loading..." : currentGPA} */}
                                    Current GPA - {CURRENT_GPA}
                                </span>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">
                                    GCE Advanced Level
                                </h3>
                                <span className="qualification__subtitle">
                                    Kurunduwatte Royal College
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2011 - 2019
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    School Education
                                </h3>
                                <span className="qualification__subtitle">
                                    Jinaraja Boys' College
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2006-2010
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>

                    <div
                        className={
                            toggleState === 2
                                ? "qualification__content qualification__content-active"
                                : "qualification__content"
                        }
                    >
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Volunteer
                                </h3>
                                <span className="qualification__subtitle">
                                    Leo Club - UoR{" "}
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>{" "}
                                    2023-present
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Qualification;
